import { isCurrentView } from "../../../shared/utils/view-util";
import { updateInputLabelDisplay } from "../../../shared/utils/input-utils";
import { getEntityTypesAndOptions } from "../../../shared/internal-pos-api/merchant-api";

function toggleInputDisplay(options, form, persistedSubTypeValue) {
  if (!form || !options || !persistedSubTypeValue) {
    return;
  }

  const entityTypes =
    document.getElementById("sbsa_business_identity_form") &&
    JSON.parse(
      document
        .getElementById("sbsa_business_identity_form")
        .getAttribute("data-mapping")
    );

  const businessSubType = persistedSubTypeValue;
  const foundEntityTypeMappings = entityTypes[businessSubType]
    ? entityTypes[businessSubType]
    : // Default when none is found
      [
        {
          business_name_text: "Business trading name",
          registered_name_text: "Business registered name",
          registration_number_text: "Business registration number",
        },
      ];
  const registeredNameContainer = $("#registered_name_container");
  // const registeredNumberContainer = $("#registered_number_container");
  const registrationNumberLabel = $("#registration_number_label");

  businessSubType == "non_profit_organisation"
    ? registrationNumberLabel.hide()
    : registrationNumberLabel.show(); // Hide description under the input

  // Set default merchant_name label/placeholder
  updateInputLabelDisplay(
    `label[for='${form}_merchant_name']`,
    `#${form}_merchant_name`,
    `${foundEntityTypeMappings[0].business_name_text}`
  );

  // Show trading name section
  if (options && options.includes("registered_business_name")) {
    updateInputLabelDisplay(
      `label[for='${form}_merchant_registered_name']`,
      `#${form}_merchant_registered_name`,
      `${foundEntityTypeMappings[0].registered_name_text}`
    );

    registeredNameContainer.show();
  } else {
    registeredNameContainer.hide();
  }

  // Show registration number section
  // if (options && options.includes("registration_number")) {
  //   updateInputLabelDisplay(
  //     `label[for='${form}_merchant_registration_number']`,
  //     `#${form}_merchant_registration_number`,
  //     `${foundEntityTypeMappings[0].registration_number_text}`
  //   );

  //   registeredNumberContainer.show();
  // } else {
  //   registeredNumberContainer.hide();
  // }
  const registrationNumberInput = $(`#${form}_merchant_registration_number`);
  const registrationNumberCheckBox = `#${form}_registration_number_required`;

  if (
    (registrationNumberInput[0] && registrationNumberInput[0].value) ||
    (registrationNumberInput[0] &&
      registrationNumberInput[0].classList.contains("is-invalid"))
  ) {
    $(`${registrationNumberCheckBox}_true`).click();
  } else {
    $(`${registrationNumberCheckBox}_false`).click();
  }

  // Show vat number
  const vatNumberInput = $(`#${form}_vat_number`);
  const vatNumberCheckBox = `#${form}_registered_for_vat`;

  if (
    (vatNumberInput[0] && vatNumberInput[0].value) ||
    (vatNumberInput[0] && vatNumberInput[0].classList.contains("is-invalid"))
  ) {
    $(`${vatNumberCheckBox}_true`).click();
  } else {
    $(`${vatNumberCheckBox}_false`).click();
  }
}

function initializeCheckBoxLogic(form) {
  const registeredNameCheckBox = `#${form}_registered_name_required`;
  const registeredNameBlock = $(`.${form}_merchant_registered_name`);
  const registeredNameInputId = $(`#${form}_merchant_registered_name`);
  const initialRegisteredNameVal = registeredNameInputId[0].value;
  const vatNumberCheckBox = `#${form}_registered_for_vat`;
  const vatNumberBlock = $(`.${form}_vat_number`);
  const registrationNumberCheckBox = `#${form}_registration_number_required`;
  const registrationNumberBlock = $(`.${form}_merchant_registration_number`);

  $(`${registeredNameCheckBox}_true`).is(":checked")
    ? registeredNameBlock.show()
    : registeredNameBlock.hide();

  $(`${registeredNameCheckBox}_true`).on("input", function (event) {
    registeredNameInputId.val(initialRegisteredNameVal);
    registeredNameBlock.show();
  });

  $(`${registeredNameCheckBox}_false`).on("input", function (event) {
    registeredNameInputId.val("");
    registeredNameBlock.hide();
  });

  $(`${vatNumberCheckBox}_true`).is(":checked")
    ? vatNumberBlock.show()
    : vatNumberBlock.hide();

  $(`${vatNumberCheckBox}_true`).on("input", function (event) {
    vatNumberBlock.show();
  });

  $(`${vatNumberCheckBox}_false`).on("input", function (event) {
    vatNumberBlock.hide();
  });

  $(`${registrationNumberCheckBox}_true`).is(":checked")
    ? registrationNumberBlock.show()
    : registrationNumberBlock.hide();

  $(`${registrationNumberCheckBox}_true`).on("input", function (event) {
    registrationNumberBlock.show();
  });

  $(`${registrationNumberCheckBox}_false`).on("input", function (event) {
    registrationNumberBlock.hide();
  });
}

$(() => {
  if (!isCurrentView(".sbsa-signup.business_identity")) {
    return;
  }

  const form = "sbsa_business_identity_form";
  const persistedMerchantValue = $(`#${form}_persisted_type_of_merchant`).val();
  const persistedSubTypeValue = $(
    `#${form}_persisted_sub_type_of_merchant`
  ).val();

  updateInputLabelDisplay(
    `label[for='${form}_merchant_name']`,
    `#${form}_merchant_name`,
    `Business name`
  );

  // Retrieves types and options to know which fields to show/hide
  getEntityTypesAndOptions().then((response) => {
    const foundEntity = response.find(
      (entity) => entity.id === persistedMerchantValue
    );

    // Retrieve options from sub_type
    const found_sub_type =
      foundEntity &&
      foundEntity.sub_types.find(
        (entity) => entity.id === persistedSubTypeValue
      );

    const options =
      found_sub_type && found_sub_type.options ? found_sub_type.options : [];

    // Toggle the display of inputs and the label text
    toggleInputDisplay(options, form, persistedSubTypeValue);
  });

  // Initializes checkbox value and bind event handler
  initializeCheckBoxLogic(form);
});
