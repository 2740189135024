import {
  isCurrentView,
} from '../../../shared/utils/view-util';

$(() => {
  if (!isCurrentView('.sbsa-signup.business_income')) { return; }

  const form = "sbsa_bank_form";
  const annualTurnover = document.getElementById(`${form}_merchant_annual_turnover`);
  const transactionRateContainer = document.getElementById('transaction_rate');
  const viewCustomRate = document.getElementById("view_custom_rate");
  const ratesContainer = document.getElementById("rates_container");
  const iconDown = document.getElementById("icon_down");
  const iconUp = document.getElementById("icon_up");
  const ratesTableData = document.getElementById("rates_table_data");
  const tdCreditRate = document.getElementById("credit_rate");
  const tdDebitRate = document.getElementById("debit_rate");
  const tdInternationalRate = document.getElementById("international_rate");
  const rawData = ratesTableData.getAttribute("data-list")
  const parsedData = JSON.parse(rawData);

  // Show on initialize
  if (annualTurnover.value >= 1) {
    transactionRateContainer.classList.replace('tw-hidden', 'tw-flex');
  }

  if (annualTurnover) {
    // On page init set rate
    calculateRateText();

    annualTurnover.addEventListener("input", () => {
      // Removes all non numeric digits
      annualTurnover.value = annualTurnover.value.replace(/\D/g,'');

      const classes = annualTurnover.value >= 1 ? ['tw-hidden', 'tw-flex'] : ['tw-flex', 'tw-hidden'];
      transactionRateContainer.classList.replace(classes[0], classes[1]);

      calculateRateText();
    });
  }

  if (viewCustomRate) {
    viewCustomRate.addEventListener('click', () => {
      showTransactionRate(ratesContainer);
    });
  }

  function calculateRateText() {
    parsedData.every((rate, index) => {
      // Return maximum
      if (annualTurnover.value >= rate.min && rate.max == null) {
        tdCreditRate.innerHTML = rate.credit_rate;
        tdDebitRate.innerHTML = rate.debit_rate;
        tdInternationalRate.innerHTML = rate.international_rate;
        return false;
      }

      // Check range
      if (annualTurnover.value >= rate.min && annualTurnover.value < rate.max) {
        tdCreditRate.innerHTML = rate.credit_rate;
        tdDebitRate.innerHTML = rate.debit_rate;
        tdInternationalRate.innerHTML = rate.international_rate;
        return false;
      }

      // Continue iteration
      return true;
    });
  }

  function showTransactionRate(container) {
    container.classList.toggle('animate-rates-container');

    const hasRatesOpen = container.classList.contains('animate-rates-container');
    if (hasRatesOpen) {
      iconDown.classList.replace('tw-flex', 'tw-hidden');
      iconUp.classList.replace('tw-hidden', 'tw-flex');
    } else {
      iconDown.classList.replace('tw-hidden', 'tw-flex');
      iconUp.classList.replace('tw-flex', 'tw-hidden');
    }
  }
});
