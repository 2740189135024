import { isCurrentView, hasCurrentView, animateElement } from '../../../shared/utils/view-util';
import { initAutocomplete, initRelatedPartyAutocomplete } from '../../../shared/utils/address-autofill-utils';
import { replaceUrlParam } from '../../../shared/utils/url-util';
import { updateInputLabelDisplay } from '../../../shared/utils/input-utils';

const form = 'sbsa_related_party_form'

function toggleConditionalFields(shouldAnimate, isValid, select, input, conditionalField) {
  const fieldConditional = $(`${conditionalField}`);
  const selectElement = $(`${select}`);
  const selectedOption = $(`${select} option:selected`);
  const inputField = $(`${input}`);

  animateElement(shouldAnimate, fieldConditional, isValid);

  if (!isValid) {
    if (select) {
      selectElement.index(0);
      selectedOption.prop('selected', false);
    }

    if (input) {
      inputField.val('');
    }
  }
}

function initializePublicOfficialChangeDetection() {
  // Public official toggle conditional fields
  const radiosPipRelative = $(`input[type=radio][name="${form}[public_official_relative]"]`);
  const radioChecked = `#${form}_public_official_relative_true:checked`;

  toggleConditionalFields(
    false,
    $(`${radioChecked}`).val(),
    `#${form}_public_official_relative_relationship`,
    `#${form}_public_official_relative_full_name`,
    '.conditional-field'
  );

  radiosPipRelative.on('change', () => {
    toggleConditionalFields(
      true,
      $(`${radioChecked}`).val(),
      `#${form}_public_official_relative_relationship`,
      `#${form}_public_official_relative_full_name`,
      '.conditional-field'
    );
  });
}

function initializeNationalityChangeDetection() {
  // Nationality toggle conditional field
  const radiosNationalityRelative = $(`input[type=radio][name="${form}[nationality_equivalent]"]`);
  const radioChecked = `#${form}_nationality_equivalent_false:checked`;

  toggleConditionalFields(
    false,
    $(`${radioChecked}`).val(),
    `#${form}_country_of_birth`,
    null,
    '.conditional-nationality'
  );

  radiosNationalityRelative.on('change', () => {
    toggleConditionalFields(
      true,
      $(`${radioChecked}`).val(),
      `#${form}_country_of_birth`,
      null,
      '.conditional-nationality'
    );
  });
}

function initializeIdentityTypeChangeDetection() {
  // Passport expiry toggle conditional field
  const dropdownIdentityType = $('#sbsa_related_party_form_identity_type');
  const passportExpiryField = '#sbsa_related_party_form_passport_expiry';

  toggleConditionalFields(
    false,
    dropdownIdentityType.val() === 'passport',
    passportExpiryField,
    null,
    '.conditional-passport-expiry'
  );

  dropdownIdentityType.on('change', () => {
    toggleConditionalFields(
      true,
      dropdownIdentityType.val() === 'passport',
      passportExpiryField,
      null,
      '.conditional-passport-expiry'
    );
  });
}

function initializeMaritalStatusTypeChangeDetection() {
  // Marital status toggle conditional field
  const dropdownMaritalStatus = $('#sbsa_related_party_form_marital_status');
  const maritalTypeField = '#sbsa_related_party_form_marital_type';

  toggleConditionalFields(
    false,
    dropdownMaritalStatus.val() === 'married',
    maritalTypeField,
    null,
    '.conditional-marital-type'
  );

  dropdownMaritalStatus.on('change', () => {
    toggleConditionalFields(
      true,
      dropdownMaritalStatus.val() === 'married',
      maritalTypeField,
      null,
      '.conditional-marital-type'
    );
  });
}

function toggleLegalShareholderAddress(shouldAnimate, isLegalShareholder) {
  const addressElement = $('#legal-shareholder-address');
  animateElement(shouldAnimate, addressElement, isLegalShareholder);
}

function toggleShareholderDocuments(shouldAnimate, isLegalShareholder) {
  const idDocumentElement = $(`#${form}_id_document_file_section`);
  const cipcDocumentElement = $(`#${form}_cipc_registration_certificate_file_section`);

  animateElement(shouldAnimate, idDocumentElement, !isLegalShareholder);
  animateElement(shouldAnimate, cipcDocumentElement, isLegalShareholder);
}

function initializePartyTypeChangeDetection() {
  const partyType = `#${form}_party_type`
  const partyTypeElement = $(`${partyType}`);
  const isLegalShareholder = partyTypeElement.val() === 'shareholder_legal_entity';

  toggleShareholderDocuments(false, isLegalShareholder);

  toggleLegalShareholderAddress(false, isLegalShareholder);

  toggleInputLabels();

  toggleConditionalFields(
    false,
    !isLegalShareholder,
    null,
    null,
    '.conditional-party-type'
  );

  $(`${partyType}`).on('change', ()=> {
    const isShareholder = partyTypeElement.val() === 'shareholder';

    if (!isShareholder) {
      $(`#${form}_nationality_equivalent_true`).prop("checked", true).change();
    }

    toggleShareholderDocuments(true, !isShareholder);

    toggleLegalShareholderAddress(true, !isShareholder);

    toggleInputLabels();

    toggleConditionalFields(
      true,
      isShareholder,
      null,
      null,
      '.conditional-party-type'
    );
  });
}

function updateRequiredShareholderQueryParam(paramValue) {
  $('.require-shareholder-href').each(function () {
    let oldUrl = $(this).attr('href'); // Retrieve current href
    let newUrl = replaceUrlParam(false, oldUrl, 'require_shareholder', paramValue);
    $(this).attr('href', newUrl); // Set new href
  });
}

// Show the related parties container once shareholders_required has a value
function showRelatedPartiesContainer(shouldAnimate, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE) {
  const relatedPartiesContainer = $('#related-parties-container');
  const merchantType = $('#related_parties_overview_form_sub_type_of_merchant').val();
  const radioShareholderRequiredChecked = $('#related_parties_overview_form_shareholders_required_true:checked');
  const radioShareholderRequiredUnchecked = $('#related_parties_overview_form_shareholders_required_false:checked');

  if (
    merchantType === SHAREHOLDER_INFO_REQUIRED_SUB_TYPE &&
    radioShareholderRequiredChecked.val() === undefined &&
    radioShareholderRequiredUnchecked.val() === undefined
  ) {
    relatedPartiesContainer.hide();
  } else {
    animateElement(shouldAnimate, relatedPartiesContainer, true);
  }
}

function checkShareholderRequiredVal(shouldAnimate, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE) {
  const radioShareholderRequiredChecked = $('#related_parties_overview_form_shareholders_required_true:checked');
  const shareholderInfo = $('#shareholder-info');
  const merchantType = $('#related_parties_overview_form_sub_type_of_merchant').val();
  const isRequired = radioShareholderRequiredChecked.val() === 'true' && merchantType === SHAREHOLDER_INFO_REQUIRED_SUB_TYPE;

  isRequired ? updateRequiredShareholderQueryParam('true') : updateRequiredShareholderQueryParam('false');
  animateElement(shouldAnimate, shareholderInfo, isRequired);
};

function add_file_block(element, event) {
  const files = event.target.files;
  const id = $(element).attr("id");
  const container = $(`#${id}_file_grid`);
  container.children().hide();

  $.each(files, function (key, value) {
    container.append(
      $("#document_uploader_template")
        .html()
        .replace("#DOC_NAME#", value.name)
    ).fadeIn(300);
    $('.delete_file').on("click", function () {
      $(this).closest(container).fadeOut(300, function () {
        $(this).children().remove();
      });
    });
  });
}

function updatePlaceholderAndError(isSelect, isRequired, shouldToggleRequired, selector, label, previousLabel) {
  const elementBlock = document.getElementsByClassName(selector);

  if (isSelect) {
    $(`#${selector} option:first`).text(
      isRequired ? `${label} *` : `${label}`
    ).trigger('change');
  }

  if (elementBlock &&
    elementBlock[0] &&
    elementBlock[0].children
  ) {
    if (elementBlock[0].children[1] && label) {
      elementBlock[0].children[1].innerHTML = isRequired ? `${label} <abbr title="required">*</abbr>` : `${label}`;
    }

    if (shouldToggleRequired && elementBlock[0].children[1] && elementBlock[0].children[2]) {
      isRequired
        ? elementBlock[0].children[0].classList.add("is-invalid")
        : elementBlock[0].children[0].classList.remove("is-invalid");
    }

    if (elementBlock[0].children[2]) {
      elementBlock[0].children[2].innerHTML = elementBlock[0].children[2].innerHTML.replace(previousLabel, label)
    }
  }
}

function updateFullNameField(isSelect, isRequired, shouldToggleRequired, selector, label, previousLabel) {
  updateInputLabelDisplay(
    `label[for='${form}_full_name']`,
    `#${form}_full_name`,
    label
  );

  updatePlaceholderAndError(
    isSelect,
    isRequired,
    shouldToggleRequired,
    selector,
    label,
    previousLabel
  );
}

function updateIdentificationField(isSelect, isRequired, shouldToggleRequired, selector, label, previousLabel) {
  updateInputLabelDisplay(
    `label[for='${form}_identification']`,
    `#${form}_identification`,
    label
  );

  updatePlaceholderAndError(
    isSelect,
    isRequired,
    shouldToggleRequired,
    selector,
    label,
    previousLabel
  );
}

function updateCountryOfResidenceField(isSelect, isRequired, shouldToggleRequired, selector, label, previousLabel) {
  updateInputLabelDisplay(
    "label[for='country_of_residence']",
    `#${form}_country_of_residence`,
    label
  );

  updatePlaceholderAndError(
    isSelect,
    isRequired,
    shouldToggleRequired,
    selector,
    label,
    previousLabel
  );
}

function toggleInputLabels() {
  const type = $(`#${form}_party_type :selected`).val();

  if (!type) { return }

  if (type === 'shareholder') {
    updateFullNameField(
      false,
      true,
      false,
      `${form}_full_name`,
      'Name and surname',
      'Business name'
    );

    updateIdentificationField(
      false,
      true,
      true,
      `${form}_identification`,
      'ID/Passport number',
      'Business registration number'
    );

    updateCountryOfResidenceField(
      true,
      true,
      false,
      `${form}_country_of_residence`,
      'Country of residence',
      'Country of incorporation'
    );
  } else {
    updateFullNameField(
      false,
      true,
      false,
      `${form}_full_name`,
      'Business name',
      'Name and surname'
    );

    updateIdentificationField(
      false,
      false,
      true,
      `${form}_identification`,
      'Business registration number',
      'ID/Passport number'
    );

    updateCountryOfResidenceField(
      true,
      true,
      false,
      `${form}_country_of_residence`,
      'Country of incorporation',
      'Country of residence'
    );
  }
}

$(() => {
  if (!isCurrentView('.sbsa-portal.related_parties.index')) { return; }

  const SHAREHOLDER_INFO_REQUIRED_SUB_TYPE = document.getElementById('new_related_parties_overview_form') && document.getElementById('new_related_parties_overview_form').getAttribute('data-entityType');

  checkShareholderRequiredVal(false, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE);
  showRelatedPartiesContainer(false, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE);

  $('input[type=radio][name="related_parties_overview_form[shareholders_required]"]').on('change', () => {
    $('#shareholders-required-text').removeClass("text-danger");
    checkShareholderRequiredVal(true, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE);
    showRelatedPartiesContainer(true, SHAREHOLDER_INFO_REQUIRED_SUB_TYPE);
  });
});

$(() => {
  if (!hasCurrentView(['.sbsa-portal.related_parties.new', '.sbsa-portal.related_parties.edit', ".sbsa-portal.related_parties.create", ".sbsa-portal.related_parties.update"])) { return; }

  const dateOfBirth = document.getElementById(`${form}_date_of_birth`);
  const passportExpiry = document.getElementById(`${form}_passport_expiry`);
  const phoneNumber = document.getElementById(`${form}_phone_number`);

  // Auto complete for address_form_fields partial
  const address1Field = document.getElementById("address1");
  const address2Field = document.getElementById("address2");
  const postalField = document.getElementById("postcode");
  const suburbField = document.getElementById("suburb");
  const cityField = document.getElementById("city");
  const provinceField = document.getElementById("province");
  const maxDateChars = 10;

  initializePublicOfficialChangeDetection();
  initializeNationalityChangeDetection();
  initializeIdentityTypeChangeDetection();
  initializeMaritalStatusTypeChangeDetection();

  initAutocomplete(false, address1Field, address2Field, postalField, suburbField, cityField, provinceField);

  ['input', 'change', 'blur', 'focusout'].forEach(eventType => {
    address1Field.addEventListener(eventType, () => validateStreetAddress());
  });

  function validateStreetAddress() {
    const value = address1Field.value;

    if (!isValidStreetAddress(value)) {
      address1Field.classList.add('is-invalid');
      address1Field.classList.remove('is-valid');
    } else {
      address1Field.classList.add('is-valid');
      address1Field.classList.remove('is-invalid');
    }
  }

  function isValidStreetAddress(streetAddressString) {
    const regEx = /^(\d+\w?)\s(.+)$/;
    return regEx.test(streetAddressString);
  }

  function addDateValidation(inputElement) {
    inputElement.addEventListener("input", () => {
      // Only allow digits and - (e.g., 1993-03-05)
      inputElement.value = inputElement.value.replace(/[^\d-]/g, '');
  
      // Remove any characters beyond the maximum allowed length
      if (inputElement.value.length > maxDateChars) {
        inputElement.value = inputElement.value.substring(0, maxDateChars);
      }
  
      // Validate the date and toggle the 'is-invalid' class
      if (!isValidDate(inputElement.value)) {
        inputElement.classList.add('is-invalid');
      } else {
        inputElement.classList.remove('is-invalid');
      }
    });
  }

  addDateValidation(dateOfBirth);
  addDateValidation(passportExpiry);

  function isValidDate(dateString) {
    const regEx = /^(19\d{2}|20[0-9]{2})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    return dateString.match(regEx) != null;
  }

  phoneNumber.addEventListener("input", (e) => {
    // Only allow digits
    phoneNumber.value = phoneNumber.value.replace(/\+D/g,'');

    if (!isValidPhoneNumber(phoneNumber.value)) {
      phoneNumber.classList.add('is-invalid');
    } else {
      phoneNumber.classList.remove('is-invalid');
    }
  });

  function isValidPhoneNumber(phoneNumberString) {
    // The below regex will match valid local and international mobile numbers
    const regEx = /^(\+27|27|0)[6-8][0-9]{8}$|^\+[1-9][0-9]{6,14}$/;
    return phoneNumberString.match(regEx) != null;
  }

  const partyType = $(`#${form}_party_type`).val();

  // Update the label required display state
  if (partyType === 'legal_entity') {
    updateIdentificationField(
      false,
      false,
      true,
      `${form}_identification`,
      'Registration number',
      null
    );

  } else {
    updateIdentificationField(
      false,
      true,
      true,
      `${form}_identification`,
      'ID/Passport number',
      null
    );
  }

  $(`#${form}_phone_number`).on('input', function (e) {
    // Remove white spaces as the user types or pastes
    if (e && e.target && e.target.value) {
      e.target.value = e.target.value.replace(/\s+/g, '');
    }
  });
});

$(() => {
  if (!isCurrentView('.sbsa-portal.related_parties.shareholder')) { return; }

  // Address fields
  const address1Field = document.getElementById("address1");
  const address2Field = document.getElementById("address2");
  const postalCodeField = document.getElementById("postcode");
  const suburbField = document.getElementById("suburb");
  const cityField = document.getElementById("city");

  initializePartyTypeChangeDetection();

  initializeNationalityChangeDetection();

  initRelatedPartyAutocomplete(address1Field, address2Field, postalCodeField, suburbField, cityField);
});
