import { isCurrentView } from "../../../shared/utils/view-util";

$(() => {
  if (!isCurrentView(".sbsa-portal.consent.index")) {
    return;
  }

  const form = "sbsa_consent_form";
  const submitButton = document.getElementById("submit-button");
  const fraudCheck = document.getElementById(`${form}_fraud_check`);
  const creditRecord = document.getElementById(`${form}_credit_record`);
  const authenticInfo = document.getElementById(`${form}_authentic_info`);
  const bioDataVerification = document.getElementById(
    `${form}_bio_data_verification`
  );

  enableSubmit(
    submitButton,
    fraudCheck,
    creditRecord,
    authenticInfo,
    bioDataVerification
  );

  fraudCheck.addEventListener("change", function () {
    enableSubmit(
      submitButton,
      fraudCheck,
      creditRecord,
      authenticInfo,
      bioDataVerification
    );
  });

  creditRecord.addEventListener("change", () => {
    enableSubmit(
      submitButton,
      fraudCheck,
      creditRecord,
      authenticInfo,
      bioDataVerification
    );
  });

  authenticInfo.addEventListener("change", () => {
    enableSubmit(
      submitButton,
      fraudCheck,
      creditRecord,
      authenticInfo,
      bioDataVerification
    );
  });

  bioDataVerification.addEventListener("change", () => {
    enableSubmit(
      submitButton,
      fraudCheck,
      creditRecord,
      authenticInfo,
      bioDataVerification
    );
  });

  const showMoreExternalMarketing = document.getElementById(
    "show_more_external_marketing"
  );
  const externalMarketingContainer = document.getElementById(
    "external_marketing_container"
  );

  const showMoreMarketing = document.getElementById("show_more_marketing");
  const marketingContainer = document.getElementById("marketing_container");

  const showMoreMarketingResearch = document.getElementById(
    "show_more_marketing_research"
  );
  const marketingResearchContainer = document.getElementById(
    "marketing_research_container"
  );

  const showMoreEmail = document.getElementById("show_more_email");
  const emailContainer = document.getElementById("email_container");

  const showMoreSms = document.getElementById("show_more_sms");
  const smsContainer = document.getElementById("sms_container");

  const showMoreTeleSales = document.getElementById("show_more_tele_sales");
  const teleSalesContainer = document.getElementById("tele_sales_container");

  const showMoreCustomerDataSharing = document.getElementById(
    "show_more_customer_data_sharing"
  );
  const customerDataSharingContainer = document.getElementById(
    "customer_data_sharing_container"
  );

  const showMoreCrossBorderSharing = document.getElementById(
    "show_more_cross_border_sharing"
  );
  const crossBorderSharingContainer = document.getElementById(
    "cross_border_sharing_container"
  );

  showMoreExternalMarketing.addEventListener("click", function () {
    showMore(externalMarketingContainer, showMoreExternalMarketing);
  });

  showMoreMarketing.addEventListener("click", function () {
    showMore(marketingContainer, showMoreMarketing);
  });

  showMoreMarketingResearch.addEventListener("click", function () {
    showMore(marketingResearchContainer, showMoreMarketingResearch);
  });

  showMoreEmail.addEventListener("click", function () {
    showMore(emailContainer, showMoreEmail);
  });

  showMoreSms.addEventListener("click", function () {
    showMore(smsContainer, showMoreSms);
  });

  showMoreTeleSales.addEventListener("click", function () {
    showMore(teleSalesContainer, showMoreTeleSales);
  });

  showMoreCustomerDataSharing.addEventListener("click", function () {
    showMore(customerDataSharingContainer, showMoreCustomerDataSharing);
  });

  showMoreCrossBorderSharing.addEventListener("click", function () {
    showMore(crossBorderSharingContainer, showMoreCrossBorderSharing);
  });

  function showMore(container, showMore) {
    container.classList.toggle("animate-show-more");

    const hasShowMoreOpen = container.classList.contains("animate-show-more");
    showMore.textContent = hasShowMoreOpen ? "Show less" : "Show more";
  }

  function enableSubmit() {
    submitButton.disabled =
      fraudCheck.checked &&
      creditRecord.checked &&
      authenticInfo.checked &&
      bioDataVerification.checked
        ? false
        : true;
  }
});
