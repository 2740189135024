import { initAutocomplete } from './utils/address-autofill-utils';

// Shared page initialization of autocomplete
$(() => {
  if (!isCurrentView('.sbsa-signup.business_location, .sbsa-portal.business_address, .payment_methods, .sbsa-portal.related_parties.shareholder')) { return; }

  // Auto complete for address_form_fields partial
  let address1Field = document.getElementById("address1");
  let address2Field = document.getElementById("address2");
  let postalField = document.getElementById("postcode");
  let suburbField = document.getElementById("suburb");
  let cityField = document.getElementById("city");
  let provinceField = document.getElementById("province");

  initAutocomplete(true, address1Field, address2Field, postalField, suburbField, cityField, provinceField);
});
