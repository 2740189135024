import { isCurrentView } from '../../../shared/utils/view-util';
import { toggleSelectPlaceholderState } from '../../../shared/utils/select-utils';
import { initAutocomplete } from '../../../shared/utils/address-autofill-utils';

$(() => {
  if (!isCurrentView('.sbsa-signup.business_location')) { return; }

  const addressContainer = document.getElementById("delivery-address-container");
  const use_business_address_true = document.getElementById("sbsa_business_location_form_use_business_address_true");
  const use_business_address_false = document.getElementById("sbsa_business_location_form_use_business_address_false");
  const phoneNumber = document.getElementById("sbsa_business_location_form_store_info_phone");
  const merchantAddress1Field = document.getElementById("address1");

  ['input', 'change', 'blur', 'focusout'].forEach(eventType => {
    merchantAddress1Field.addEventListener(eventType, () => validateStreetAddress(merchantAddress1Field));
  });

  // Delivery address fields
  const address1Field = document.getElementById("delivery_address1");
  const address2Field = document.getElementById("delivery_address2");
  const postalCodeField = document.getElementById("delivery_postcode");
  const suburbField = document.getElementById("delivery_suburb");
  const cityField = document.getElementById("delivery_city");
  const provinceField = document.getElementById("delivery_province");

  ['input', 'change', 'blur', 'focusout'].forEach(eventType => {
    address1Field.addEventListener(eventType, () => validateStreetAddress(address1Field));
  });

  function validateStreetAddress(streetAddress) {
    const value = streetAddress.value;

    if (!isValidStreetAddress(value)) {
      streetAddress.classList.add('is-invalid');
      streetAddress.classList.remove('is-valid');
    } else {
      streetAddress.classList.add('is-valid');
      streetAddress.classList.remove('is-invalid');
    }
  }

  function isValidStreetAddress(streetAddressString) {
    const regEx = /^(\d+\w?)\s(.+)$/;
    return regEx.test(streetAddressString);
  }

  addStoreTypeListener(use_business_address_true);
  addStoreTypeListener(use_business_address_false);
  initAutocomplete(true, address1Field, address2Field, postalCodeField, suburbField, cityField, provinceField);

  if (use_business_address_false && use_business_address_false.checked) {
    showAddressSection()
  }

  phoneNumber.addEventListener("input", (e) => {
    // Only allow digits
    phoneNumber.value = phoneNumber.value.replace(/\+D/g,'');

    if (!isValidPhoneNumber(phoneNumber.value)) {
      phoneNumber.classList.add('is-invalid');
    } else {
      phoneNumber.classList.remove('is-invalid');
    }
  });

  function isValidPhoneNumber(phoneNumberString) {
    // The below regex will match valid local and international mobile numbers
    const regEx = /^(\+27|27|0)[6-8][0-9]{8}$|^\+[1-9][0-9]{6,14}$/;
    return phoneNumberString.match(regEx) != null;
  }

  function showAddressSection() {
    // Show container when delivery address checkbox is checked
    if (use_business_address_false.checked === true) {
      addressContainer.classList.remove('tw-hidden');
      addressContainer.classList.add('tw-flex');

      if (!address1Field.value) {
        // Only focus when delivery address has no value
        address1Field.focus();
      }
    } else {
      clearDeliveryAddressFields();
      addressContainer.classList.remove('tw-flex');
      addressContainer.classList.add('tw-hidden');
    }
  }

  function addStoreTypeListener(input) {
    if (input) {
      input.addEventListener('change', (event) => {
        if (use_business_address_false.checked === true) {
          clearDeliveryAddressFields();
        }

        showAddressSection();
      });
    }
  }

  function clearDeliveryAddressFields() {
    // Clear input values
    address1Field.value = "";
    address2Field.value = "";
    postalCodeField.value = "";
    suburbField.value = "";
    cityField.value = "";
    provinceField.value = "";

    // Clear html attribute
    address1Field.setAttribute("value", "");
    address2Field.setAttribute("value", "");
    postalCodeField.setAttribute("value", "");
    suburbField.setAttribute("value", "");
    cityField.setAttribute("value", "");
    provinceField.setAttribute("value", "");

    toggleSelectPlaceholderState();
  }
});
